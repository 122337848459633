<template>
  <div class="p-6">
    <div class="max-w-7xl mx-auto bg-white p-8 rounded-2xl border border-gray-200 ">
      <h1 class="text-2xl font-bold text-gray-800 mb-6">Öğretmen Listesi</h1>
      <ul class="space-y-4">
        <li 
          v-for="teacher in teachers" 
          :key="teacher.name" 
          class="flex justify-between items-center bg-gray-50 p-4 rounded-lg border"
        >
          <div>
            <h2 class="text-lg font-semibold text-gray-900">{{ teacher.name }}</h2>
            <p class="text-gray-600">Eğitim Yılı: {{ teacher.year }}</p>
            <p class="text-gray-600">Branş: {{ teacher.subject }}</p>
          </div>
          <router-link to="/okul/isik"
            class="bg-gray-900 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Görüşme Başlat
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TeacherList',
  data() {
    return {
      teachers: [
        {
          name: "Ahmet Yılmaz",
          year: "2023-2024",
          subject: "Matematik",
        },
        {
          name: "Ayşe Kaya",
          year: "2023-2024",
          subject: "Fen Bilgisi",
        },
        {
          name: "Mehmet Demir",
          year: "2022-2023",
          subject: "Türkçe",
        },
      ],
    };
  },
});
</script>

<style scoped>

</style>
