import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue'; 
import Assistant from '@/views/Assistant.vue';
import TeacherList from '@/views/TeacherList.vue';
import { useAuthStore } from '../stores/auth';

const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/assistant',
    component: Assistant,
  },
  {
    path: '/teacher-list',
    component: TeacherList,
  },
  {
    path: '/demo',
    component: () => import('../views/Assistant.vue'),
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/okul/:slug',
    name: 'UserDetail',
    component: Assistant,
  },
];

const router = createRouter({
  history: createWebHistory(), // HTML5 tarih API'sini kullanarak geçerli bir tarayıcı geçmişi oluşturur.
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
 
  if (to.path === '/teacher-list' && !authStore.isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});


export default router;
