<template>

<div class="flex flex-col sm:flex-row w-full max-w-7xl mx-auto bg-zinc-50 shadow-lg p-4 mb-0 rounded-t-md border-b">
  <div v-if="user" class="flex sm:flex-row  sm:space-x-4 w-full">
    <div class="flex items-center space-x-4 w-full">
      <img :src="user ? require(`@/assets/${user.logoUrl}`) : ''" alt="Logo" class="w-20 h-20 rounded-full border" />
      <div class=" sm:flex-1">
        <h2 class="text-lg font-semibold text-indigo-600">{{ user.name }}</h2>
        <p class="text-zinc-500 text-sm">{{ user.description }}</p>
      </div>
    </div>
  </div>
</div>

</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'UserDetail',
  setup() {
    const route = useRoute();
    const user = ref<any>(null);

    // Dummy data
    const users = [
      { username: 'isik', name: 'HIGH SCHOOL OKULLARI', logoUrl: 'school-logo.jpg', description: 'Okul açıklaması' },
      { username: 'demo', name: 'DEMO HIGH SCHOOL', logoUrl: 'school-logo.jpg', description: 'Demo okulumuzun yapay zeka destekli öğretmen asistanları.' },
      { username: 'alex_jones', name: 'Alex Jones', logoUrl: 'school-logo.jpg', description: 'Okul açıklaması' },
    ];

    watchEffect(() => {
      const slug = route.params.slug;
      if (slug) {
        user.value = users.find((u) => u.username === slug);
      }
    });

    return { user };
  }
});
</script>

<style scoped>
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
</style>